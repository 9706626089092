import PropTypes from "prop-types"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import logo from '../images/logo-radnet.svg'

const Header = ({ fluid, pageName }) => (
  <header>
    <Container fluid={fluid}>
      <Row>
        <Col sm="3">
        <p><img src={logo} style={{width:`180px`, height: `auto`}} />
        <br /><span style={{fontSize:`10px`, paddingLeft:`4rem`, position:`relative`, top:`-1rem`}}>Leading Radiology Forward</span></p>
        </Col>
        <Col sm="9" className="text-right">
          <h2>{pageName}</h2>
        </Col>
      </Row>
    </Container>
  </header>
)

Header.propTypes = {
  pageName: PropTypes.string,
  fluid: PropTypes.bool,
}

Header.defaultProps = {
  pageName: ``,
  fluid: false
}

export default Header
